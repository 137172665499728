@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;0,600;1,200;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT:wght@400;700&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  background: "var(--primary)";
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Old Standard TT";
  src: local("Old Standard TT");
}

body {
  margin: 0;
  font-family: "Old Standard TT", "Source Sans Pro", "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: 'Old Standard TT', source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

/* HEADER */
.header{
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 90px;
  padding: 0 10px;
  background: linear-gradient(to left, #c06c84, #6c5b7b, #355c7d);
  font-weight: bold;
  color: var(--secondary-text);
  box-shadow: 10px 10px;
}

.headerItems{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    transition: all ease-in-out 300ms;
    z-index: 100;
    color: white;
}
  

/* Image/banner */

.whiteDiv{
  width: 290px;
  height: 290px;
  background-color: white;
  position: absolute;
  top: 50;
  border-radius: 100%;
  animation: opac 4s forwards;
}

@keyframes opac {
  to{
    opacity: 0;
  }
}
h2{
  font-size: 3.5em;
  font-weight: 700;
}

/* Home */
.home_first_row{
  display: flex;
  justify-content: space-around;
  width: 94vw;
  color: white;
}
.home_first_row p{
  font-size: 1.2em;
  line-height: 1.4;
}
.span{
  color: #FFD237;
}

.first_row_right{
  width: 50%;
  margin-top: 20px;
}
.first_row_left{
  margin-top: 20px;
  justify-content: center;
}
.home_first_row img{
  width: 100%;
  height: auto;
}
.first_row_right h3{
  font-size: 1.8em;
  width: 80%;
}

/* Description section */
.description_section{
  margin-top: 50px;
  color: white;
  display: flex;
  width: 94vw;
  padding: 40px;
  justify-content: center;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;

}
.description_section h2{
  font-size: 2.8em;
}

.description_container{
  width: 60%;
  margin-right: 30px;
  padding-right: 40px;
}

.description_container h3{
  color: #FFD237;
  font-size: 2.6em;
  margin-top: 50px;
  margin-bottom: 30px;;
}

.description_container p{
  width: 90%;
  font-size: 1.45em;
}

.description_img{
  width: 40%;
  overflow: hidden;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  margin-top: 60px;
}



/* PHASE */
.phase_item{
  width: 55%;
}

.h1{
  font-size: 2em;
}

.description{
  padding: 8px;
  border-radius: 10px;
  background-color: var(--secondary);
  margin-bottom: 10px;
  transition: all ease-in-out 200ms;
}

.description:hover{
  transform: scale(1.3);
}

/* ABOUT */
.about_wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 2em;
  margin-top: 40px;
  width: 100%;
  
}
.about_container{
  display: flex;
  margin-top: 10px;
  width: 100%;
  height: auto;
  padding: 40px 0;
  background-image: url('img/about-bg.png');
  /* background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat; */
  background-size: cover;
}
.about_title{
  margin-bottom: 20px;
}
.about1{
  font-size: 0.8em;
  font-weight: 200;
}
.about_title2{
  align-self:flex-start;
}
.about_title{
  font-weight: 600;
}
.about_wrapper p{
  width: 100%;
}
.about1{
  width: 680px;
  font-size: 0.7em;
  text-align: center;
  margin-bottom: 20px;
}
.about2{
  width: 680px;
  font-size: 0.5em;
  margin-bottom: 20px;
  font-weight: 200;
  line-height: 1.9;
}
.about_img{
  width: 70vw;
}
.about_img img{
  width: 100%;
}
.about_text{
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  margin-left: 10%;
  width: 50%;
}


/* ROADMAP */

.roadmap{
  padding: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  border-bottom: 1px solid gray;
}
.roadmapWrapper{
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: var(--accent);
  align-items: center;
}
.roadmap img{
  width: 90%;
}

.roadmap h2{
  font-size: 3.4em;
  color: white;
  margin-left: 55%;
}

.roadmap li, .roadmap p{
  font-size: 1.4em;
  color: white;
  text-align: justify;
}
.roamdap_content{
  width: 50%;
}

.visual_rm_container{
  display: flex;
  margin-top: 50px;
  width: 90%;
  height: max-content;
  /* align-items: center; */
  justify-content: space-evenly;
}

.visual_rm_container h3{
  font-size: 2.4em;
}
.rm_element1{
  height: 100%;
}
.rm_element1 img{
  height: 300px;
  width: auto;
}
.rm_element1, .rm_element2, .rm_element3 {
  width: 25vw;
  padding: 20px;
  
}

.rm_element1 h2, .rm_element2 h2, .rm_element3 h2{
  text-align: left;
}
.rm_element1 p, .rm_element2 p, .rm_element3 p{
  font-weight: 300;
  font-size: 1.4em;
  text-align: left;
}

/* NFT Traits */
.nft_traits{
  color: white;
  width: 94vw;
  margin-top: 80px;
}
.nft_traits h2{
  font-size: 3em;
}



/* News */
.news{
  display: flex;
  color: white;
  margin: 40px 0 20px 0;
  width: 80%;
}
.news_col_left{
  width: 50%;
  display: flex;
  flex-direction: column;
}
.news_col_left p{
  font-size: 1.6em;
  font-weight: 100;
  margin-top: 60px;
}
.news_col_right{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.news_col_right a{
  display: flex;
  justify-content: center;
  align-items: center;
}
.watch_container{
  display: flex;
  justify-content: start;
  align-items: center;
}
.watch_button{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  text-decoration: none;
  color: white;
  background: var(--gradient-yellow);
  width: 200px;
  height: 50px;

  border: 1px solid white;
  border-radius: 10px;

  font-size: 2em;
}
.news img{
  width: 90%;
  margin: 0 0 20px 0;
}
.news_img_bottom{
  height: 300px;
  object-fit: cover;
  object-position: top;
}

/* Team */
.team_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 95%;
  padding: 22px;
  color: white;
}
.team_container h1{
  font-size: 2em;;
}

.team_members{
  display: grid;
  gap: 4vw;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  width: 90%;
}

.member_item{
  width: auto;
  height: auto;
  display: flex;
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.member_img{
  width: 80%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background-color: white;
}

.member_item h3{
  font-size: 1.6em;
}

.member_container{
  display: flex;
  align-items: center;
}

/* FAQ */
.faq{
  width: 90%;
  margin-top: 60px;

  color: white;
}

.faq_container{
  margin-top: 30px;
}
.faq_subcontainer{
  display: flex;
  align-items: center;
}
.faq_subcontainer button{
  margin-left: 20px;
  border: none;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 100px;
  font-family: 'Old Standard TT';
  height: 30px;
  width: 30px;
  color: white;
  cursor: pointer;
}

.faq_container h3{
  margin: 10px 0;

  font-size: 1.7em;
}

.faq_container a{
  text-decoration: none;
  color: var(--primary-text);
}


/* Footer */
.footer{
  height: 10px;
  color: white;
  margin-top: 10px;
  text-align: center;
}

/* Mobile Roadmap */
@media only screen and (max-width: 1032px){
  .home_first_row{
    flex-direction: column;
    align-items: center;
  }
  .first_row_left{
    width: 90%;
  }
  .first_row_right{
   width:74%;
  }

  .roadmap h2{
    margin-left: 0;
  }

  .visual_rm_container{
    flex-direction: column;
    align-items: center;
  }
  .rm_element1, .rm_element2, .rm_element3{
    width: auto;
  }

  .news{
    flex-direction: column;
    align-items: center;
  }
  .news_col_left, .news_col_right{
    width: 100%;
  }

  .yt_vid, .news img{
    width: 100%;
  }

  .watch_container{
    justify-content: center;
    margin-bottom: 30px;
  }

  .middle_element_rm{
    margin: 0;
  }

  .rm_phase1, .rm_phase2, .rm_phase3{
    width: 60vw;
    margin: 10px 10px;
  }
}

@media only screen and (max-width: 750px){
  h2{
    font-size: 2.8em;
  }
  
  .about_wrapper{
    width: 100%;
  }

  .about_wrapper p{
    font-size: 0.5em;
    width: 95%;
    color: white;
  }

  .about_container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about_img{
    width: 80%;
    display: flex;
    justify-content: center;
  }
  .about_img img{
    width: 60%;
    align-self: center;
  }
  .about_text{
    width: auto;
    margin-top: 40px;
  }

  .team_members{
    /* width: auto; */
  }
  .member_item{
    width: 140px;
  }
  .description_container{
    width: 90%;
    margin-right: 0;
    padding: 0;
    text-align: center;
    
  }
  .description_container h3{
    font-size: 1.6em;
  }
  .description_container p{
    font-size: 1.2em;
    width: 100%;
  }
  .description_img{
    display: none;
  }

  /* FAQ */
  .faq_container h3{
    font-size: 1.2em;
  }
}



/* BG effects */

.bg_effect1{
  position: absolute;
  width: 208px;
  height: 208px;
  left: -28px;
  top: 1764px;

  background: #00B55E;  
  filter: blur(614px);
}